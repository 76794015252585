import { oneLineTrim } from 'common-tags'
import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class SearchAutoComplete extends ComponentLoader {
  static SETTINGS = {
    source:    '/search_suggestions',
    minLength: 2,
    appendTo: '.js-search-suggestions',

    select: (event, ui) => {
      if (ui.item) {
        event.target.value = ui.item.value // eslint-disable-line no-param-reassign
      } else {
        // A blank item is the eula agreement link and we want to trigger a click event.
        $('#eula-agreement a').trigger('click')
        return
      }

      // Submit the form unless we’re in the search filters modal
      if (!event.target.closest('#js-mobile-search-filters')) {
        $('#search-form').submit()
      }
    },
    focus: (event, ui) => {
      if (ui.item) {
        $(event.target).attr('aria-activedescendant', ui.item.id)
      } else {
        // If no active item is present, the focus is on the eula_agreement link.
        $(event.target).attr('aria-activedescendant', 'eula-agreement')
      }
    },
    close: (event) => {
      if (event) {
        const $target = $(event.target)

        $target.attr('aria-expanded', 'false')
        $target.removeAttr('aria-activedescendant')
      }
    },
  }

  // NOTE: jQuery UI sets `this` to the jQuery UI Autocomplete object
  /* eslint-disable no-underscore-dangle, no-param-reassign, max-len */
  static OVERRIDES = {
    _renderItem(ul, item) {
      item.value = `${item.suggestion}, ${item.state_abbreviation}`
      item.label = oneLineTrim`
        <a class="ui-corner-all" id='ui-item-${item.id}'>
          <span>${item.suggestion}</span>
          <strong>${item.state_abbreviation}</strong>
        </a>
      `

      return $(`<li class="clearfix" role="option" id=${item.id}></li>`)
        .data('item.autocomplete', item)
        .append(item.label)
        .appendTo(ul)
    },

    _renderMenu(ul, items) {
      ul.attr('role', 'listbox')

      const $listId = ul.attr('id')
      $('#search-location').attr('aria-controls', $listId)
      $('.js-search-autocomplete').attr('aria-expanded', 'true')

      items.forEach(item => this._renderItemData(ul, item))
      // Show EULA agreement compliance
      ul.prepend(
        $(oneLineTrim`
          <li class="clearfix ui-menu-item autocomplete-help-text" role="option" id="eula-agreement">
            <a class="ui-corner-all ui-menu-item-wrapper fine-print" tabindex="-1">
              <span>By searching, I agree to the <em>license agreement</em></span>
            </a>
          </li>
        `).on('click', (event) => {
          event.stopPropagation()
          window.location.href = '/eula_agreement'
        }),
      )
    },
  }
  /* eslint-enable */

  static initialize() {
    document
      .querySelectorAll('.js-search-autocomplete')
      .forEach((input) => {
        $(input).one('focus', this.onUserWillInteract)
        $(input).on('blur', this.onBlur)
      })
  }

  static onUserWillInteract = async (event) => {
    const { default: Autocomplete } = await import('packs/lazy/Autocomplete')

    new Autocomplete(event.currentTarget, this.SETTINGS, this.OVERRIDES).render()

    this.moveLiveRegion()
  }

  // NOTE: aria-live element is added dynamically so we need to find the element in the document object.
  // By default the aria-live tag is appended to the bottom of the page. We want to move this closer to the search field.
  static moveLiveRegion = () => {
    $('.js-search-results-count').append($(document)
      .find("[aria-live='assertive'][aria-relevant='additions']")
      .addClass('sr-only'))
  }

  static onBlur = () => {
    $(document).find("[aria-live='assertive'][aria-relevant='additions']").empty()
  }
}

import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'
import EstatelyEvents  from 'legacy/lib/EstatelyEvents'

export default class SaveSearchButton extends ComponentLoader {
  static mount() {
    $(document).on('click', '.gtm-save-search-btn', (event) => {
      event.preventDefault()
      $(event.currentTarget).trigger(EstatelyEvents.SAVE_SEARCH.REQUEST)
    })
  }
}

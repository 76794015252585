import $ from 'legacy/vendor/jquery.custom'
import EstatelyEvents from 'legacy/lib/EstatelyEvents'

import ComponentLoader from 'legacy/lib/ComponentLoader'
import { onceInView } from 'legacy/lib/lazy-loading'

export default class AjaxLoadedContent extends ComponentLoader {
  static initialize() {
    onceInView('[data-ajax-load-url]', this.onLazyLoad)
  }

  static onLazyLoad = (el) => {
    const $el = $(el)

    const url = el.dataset.ajaxLoadUrl
    delete el.dataset.ajaxLoadUrl // eslint-disable-line no-param-reassign

    // Replace $el’s HTML with the response from `url`
    $el.load(url, (newContent) => {
      $el.trigger(EstatelyEvents.AJAX_CONTENT.LOADED, [newContent])
    })
  }
}

// These are used as custom jQuery events, and follow jQuery event conventions:
// See: https://learn.jquery.com/events/introduction-to-custom-events/#naming-custom-events
// See: https://api.jquery.com/event.namespace/

// IMPORTANT: Use `:` as a prefix to avoid triggering actual DOM events

const EstatelyEvents = {
  AJAX_CONTENT: {
    LOADED:            ':loaded.estately.ajax-content',
  },

  SAVE_SEARCH: {
    REQUEST:           ':request.estately.save-search',
    SUCCESS:           ':success.estately.save-search',
    ERROR:             ':error.estately.save-search',
  },

  STATE_CHANGE: {
    SHOWING_REQUEST:   ':state-change.estately.showing-requested',
    LOGIN:             ':state-change.estately.logged-in',
    PROPERTY_SAVE:     ':state-change.estately.property-saved',
  },

  USER: {
    WILL_INTERACT:     ':will-interact.estately.user', // Used for lazy-loading content
  },
}

export default EstatelyEvents

import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'
import EstatelyEvents  from 'legacy/lib/EstatelyEvents'


const seconds = ms => Math.floor(ms / 1000)
const minutes = ms => Math.floor(ms / 60000)
const hours   = ms => Math.floor(ms / 3600000)
const days    = ms => Math.floor(ms / 86400000)

const pluralize = (count, singular) => `${count} ${singular}${count === 1 ? '' : 's'}`

export function timeSinceDate(date) {
  const ms = new Date().valueOf() - date.valueOf()

  switch (true) {
    case (days(ms)    > 0): return pluralize(days(ms),    'day')
    case (hours(ms)   > 0): return pluralize(hours(ms),   'hour')
    case (minutes(ms) > 0): return pluralize(minutes(ms), 'minute')
    default:                return pluralize(seconds(ms), 'second')
  }
}

export default class TimeSinceDate extends ComponentLoader {
  static mount() {
    $(document).on(EstatelyEvents.AJAX_CONTENT.LOADED, (event) => {
      const newContent = event.currentTarget
      this.initialize(newContent)
    })
  }

  static initialize(root = document) {
    root.querySelectorAll('.js-time-since-today').forEach((timestamp) => {
      const iso8601 = timestamp.dataset.listingCreatedAt.trim()
      const date    = new Date(iso8601)

      /* eslint-disable no-param-reassign */
      timestamp.textContent = timeSinceDate(date)
      timestamp.title       = date.toLocaleString()
      /* eslint-enable */
    })
  }
}

import $ from 'legacy/vendor/jquery.custom'

export function onceInView(selector, callback) {
  $(selector).each((_i, el) => {
    if (el.matches('.lazyloading, .lazyloaded')) return void callback(el)

    // Sanity check: Ensure the element is being lazy-loaded
    if (global.ENV === DEVELOPMENT && !el.matches('.js-lazyload')) {
      console.error(el)
      throw new Error(`
        ${selector} is missing required .js-lazyload class.
        You may want to add .lazyload-with-placeholder too
        (unless you want ${selector} to fade-in when lazy-loaded)
      `)
    }

    $(el).one('lazybeforeunveil', (event) => {
      callback(event.target)
    })
  })
}

export function lazyLoadWhenInView(selector, importer) {
  onceInView(selector, async () => {
    const { default: Component } = await importer()
    Component.lazyLoad()
  })
}

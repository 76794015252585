import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'

export default class ClearableInput extends ComponentLoader {
  static initialize() {
    document
      .querySelectorAll('.js-clearable-input')
      .forEach(clearable => new ClearableInput(clearable))
  }

  constructor(clearable) {
    super()

    this.$clearable = $(clearable)
    this.$clearBtn  = this.$clearable.siblings('.js-clear-input-btn')

    this.render()

    this.$clearBtn.on('click',  this.onClear)
    this.$clearable.on('input', this.render)
  }

  render = () => {
    const valuePresent = !!this.$clearable.val()
    this.$clearable.toggleClass('clearable', valuePresent)
    this.$clearBtn.toggleClass('hidden',    !valuePresent)
  }

  onClear = (event) => {
    event.preventDefault()
    this.$clearable.val('').trigger('input').focus()
  }
}

import $ from 'legacy/vendor/jquery.custom'

// NOTE: We have to capture keyboard focus within the modal when a modal is opened for
// accessibility reasons. Issue: 22210
export default class ModalFocus {
  static captureTabEvent()  {
    const focusableElements = 'button, a, input, select, textarea'

    $('.modal').keydown((e) => {
      if (!(e.key === 'Tab')) {
        return
      }

      const $target = $(e.target)
      // We have to find the closest modal because there can be multiple modals on one page.
      const $closest = $target.closest('.modal')

      const $focusableContent = $closest.find(focusableElements).not(':hidden,[tabindex=-1]')
      const firstFocusableElement = $focusableContent[0]
      const lastFocusableElement = $focusableContent[$focusableContent.length - 1]

      if (e.target === firstFocusableElement) {
        // Shift + Tabbing up on first element, will move focus to last element.
        if (e.shiftKey) {
          e.preventDefault()
          $(lastFocusableElement).focus()
        } else if (firstFocusableElement === lastFocusableElement) {
          // Tabbing on first element when there is no other focusable element will
          // re-focus on the first element.
          e.preventDefault()
          $(firstFocusableElement).focus()
        }
      } else if ((e.target === lastFocusableElement) && !e.shiftKey) {
        // Tabbing on last element will move focus to the first element.
        e.preventDefault()
        $(firstFocusableElement).focus()
      }
    })
  }
}

import $ from 'legacy/vendor/jquery.custom'

import ComponentLoader from 'legacy/lib/ComponentLoader'
import EstatelyEvents  from 'legacy/lib/EstatelyEvents'

export default class SaveSearchForm extends ComponentLoader {
  static mount() {
    $(document).on('submit', '.gtm-save-search-form', this.onSubmit)
    $(document).on(EstatelyEvents.SAVE_SEARCH.ERROR, '.gtm-save-search-form', this.onError)
  }

  static onSubmit(event) {
    event.preventDefault()
    const $form = $(event.currentTarget)

    $form.find('.show-error').text('').addClass('hide') // Clear errors
    $form.trigger(EstatelyEvents.SAVE_SEARCH.REQUEST)
  }

  static onError(event, error) {
    const $form = $(event.currentTarget)

    $form.find('.show-error')
      .append($(error.responseText).html())
      .removeClass('hide')
  }
}

/*!
 * Copyright 2015 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
 * or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/*
 * @see https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 */

// TODO: We can delete this entire file once Safari support improves
// See https://caniuse.com/requestidlecallback

// TODO: Replace with `globalThis` when browser support improves
// See https://caniuse.com/mdn-javascript_builtins_globalthis
const windowOrGlobal = global || window

export const requestIdleCallback = windowOrGlobal.requestIdleCallback || (
  callback => setTimeout(
    () => {
      const start = performance.now()

      callback({
        didTimeout:    false,
        timeRemaining: () => Math.max(0, 50 - (performance.now() - start)),
      })
    },
    1,
  )
)

export const cancelIdleCallback = windowOrGlobal.requestIdleCallback || (
  id => clearTimeout(id)
)

export default requestIdleCallback

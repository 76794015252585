import $ from 'legacy/vendor/jquery.custom'

import EstatelyEvents  from 'legacy/lib/EstatelyEvents'
import ComponentLoader from 'legacy/lib/ComponentLoader'
import Accordion from 'legacy/views/listings/Accordion'
import Cookies from 'legacy/lib/Cookies'

export default class Header extends ComponentLoader {
  static mount() {
    $(document).on(EstatelyEvents.STATE_CHANGE.LOGIN, this.onLogin)
    Accordion.mount()
  }

  static initialize() {
    $('.js-show-search-bar').on('click', this.onSearchBarToggleClick)
    $('.special-dropdown-links, .accordian-item').on('click', this.onSitewideHeaderNavigationClick)
    $('.modal-sidebar').on('click', '[data-toggle="modal"]', this.onSidebarNestedModalClick)

    // The logout item in the sidebar is a form (rather than a link, like the other items). As a result,
    // only the text itself is clickable. This event handler makes the entire list item clickable.
    $('.logout-item').on('click', this.onLogoutItemClick)
  }

  static onLogin = () => {
    const $headerNavContent = $('#js-header-nav .modal-content')

    $headerNavContent.load(`/account/header_nav?return_to=${window.location.pathname}`, (newContent) => {
      $headerNavContent.trigger(EstatelyEvents.AJAX_CONTENT.LOADED, [newContent])
    })
  }

  static onSearchBarToggleClick = () => {
    const $headerSearch = $('.header-search')
    const toggleOpen = !$headerSearch.is(':visible')

    $headerSearch.toggleClass('header-search-open', toggleOpen)

    if (toggleOpen) {
      $headerSearch.find('#search-location').focus()

      // Close the search if you click anywhere else on the page
      $(window).on('click.header-search', (event) => {
        const isHeaderSearchClick = $(event.target).closest('.header-search,.js-show-search-bar,.ui-autocomplete').length

        if (!isHeaderSearchClick) this.onSearchBarToggleClick()
      })
    } else {
      $(window).off('click.header-search')
    }
  }

  static onSidebarNestedModalClick = (event) => {
    const $sidebar = $(event.delegateTarget)

    if (!$sidebar.hasClass('fade')) return

    $sidebar.removeClass('fade')
    $sidebar.modal('hide').on('hidden.bs.modal', () => $sidebar.addClass('fade'))
  }

  static onLogoutItemClick = (event) => {
    localStorage.removeItem('first-time-login-timestamp')
    $(event.target).submit()
  }

  static onSitewideHeaderNavigationClick = (event) => {
    const cityNameCookie = Cookies.get('city_name')
    const cityName = cityNameCookie.split('/')[1].replaceAll('_', ' ')

    $(event.currentTarget).find('.special-dropdown-menu-link, .accordion-link').each((_, link) => {
      const currentPrefix = $(link).data('linkPrefix')
      if (!currentPrefix) return

      $(link).text((_index, text) => text.replace(currentPrefix, cityName))
      $(link).data('linkPrefix', cityName)

      const currentHref = $(link).attr('href')
      const newHref = currentHref.replace(/^\/[^/]+\/[^/]+/, `/${cityNameCookie}`)

      $(link).attr('href', newHref)
    })
  }
}
